@import url("https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mochiy Pop One", sans-serif;
}
body {
  background-color: #ffffff;
}
.app-container {
  max-width: 992px;
  padding: 2rem 1rem;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.text-box {
  margin: auto;
}
.title {
  font-size: 3rem;
  color: #1c2630;
  font-weight: 700;
  line-height: 1.5;
}
.title span {
  display: block;
  color: #9149f9;
}
.start-btn {
  padding: 1.25rem 2.25rem;
  border-radius: 2rem;
  border: 2px solid #9149f9;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  margin-top: 2rem;
  font-size: 0.8rem;
  transition: all 0.25s;
}
.start-btn:hover {
  background-color: #9149f9;
  color: #fff;
}
.start-btn:active {
  transform: scale(0.9);
}
.board-area {
  width: 320px;
  margin: 0 auto;
}
.players {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.player {
  font-size: 1rem;
  color: #e3e3e3;
  padding-bottom: 0.75rem;
  border-bottom: 4px solid #e3e3e3;
  margin-bottom: 2rem;
}
.player span {
  margin-left: 0.5rem;
  font-size: 1.25rem;
}
.player.first.active {
  border-color: #3ac7a5;
  color: #3ac7a5;
}
.player.second.active {
  border-color: #2b85eb;
  color: #2b85eb;
}
.board {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 320px;
  height: 320px;
  margin: 0 auto;
  background-color: transparent;
  position: relative;
}
.square {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-size: 48px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;
  z-index: 1;
}
.square:nth-child(1),
.square:nth-child(2) {
  border-right: 2px solid #e3e3e3;
  border-bottom: 2px solid #e3e3e3;
}
.square:nth-child(3) {
  border-bottom: 2px solid #e3e3e3;
}
.square:nth-child(4) {
  border-right: 2px solid #e3e3e3;
}
.square:nth-child(5) {
  border-right: 2px solid #e3e3e3;
}
.square:nth-child(7),
.square:nth-child(8) {
  border-right: 2px solid #e3e3e3;
  border-top: 2px solid #e3e3e3;
}
.square:nth-child(9) {
  border-top: 2px solid #e3e3e3;
}
.square.win.X {
  background-color: #a0eedc83;
}
.square.win.O {
  background-color: #82b6f283;
}
.square:hover {
  background-color: #f9f9f9;
}
.square:focus {
  outline: none;
}
.x,
.winner.X {
  color: #3ac7a5;
}
.o,
.winner.O {
  color: #2b85eb;
}
.status {
  position: relative;
  font-size: 1.75rem;
  margin-top: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 768px) {
  .app-container {
    grid-template-columns: repeat(1, 1fr);
    min-height: auto;
  }
  .text-box {
    text-align: center;
    margin: 2rem auto;
  }
  .title {
    font-size: 1.5rem;
  }
  .start-btn {
    padding: 0.75rem 1.5rem;
    font-size: 0.6rem;
  }
}
